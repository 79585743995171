<template>
  <div>
    <h1 class="ma-2 ml-0">Vendas</h1>
    <v-row class="mb-5">
      <v-col cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ parseCurrency(dashboard.ordersTotal) }}
              </h2>
              <span>{{ ordersTotal.title }}</span>
            </div>

            <v-avatar
              :color="resolveUserTotalIcon(ordersTotal.title).color"
              :class="`v-avatar-light-bg ${
                resolveUserTotalIcon(ordersTotal.title).color
              }--text`"
            >
              <v-icon
                size="25"
                :color="resolveUserTotalIcon(ordersTotal.title).color"
                class="rounded-0"
                >{{ resolveUserTotalIcon(ordersTotal.title).icon }}</v-icon
              >
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ parseCurrency(dashboard.cancelleds) }}
              </h2>
              <span>Cancelamentos</span>
            </div>

            <v-avatar color="error" class="v-avatar-light-bg success --text">
              <v-icon size="25" color="error" class="rounded-0">{{
                icons.mdiInformation
              }}</v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ parseCurrency(dashboard.billeds) }}
              </h2>
              <span>Faturadas</span>
            </div>

            <v-avatar color="success" class="v-avatar-light-bg success --text">
              <v-icon size="25" color="success" class="rounded-0">{{
                icons.mdiInformation
              }}</v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ parseCurrency(toBillSum) }}
              </h2>
              <span>Á Faturar</span>
            </div>

            <v-avatar color="success" class="v-avatar-light-bg success --text">
              <v-icon
                size="25"
                :color="toBillSum > 0 ? 'success' : 'secondary'"
                class="rounded-0"
                >{{ icons.mdiShopping }}</v-icon
              >
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card>
      <v-card-title
        class="d-flex align-center justify-space-between flex-wrap py-sm-2 py-6"
      >
        <div class="row my-2v align-center">
          <v-text-field
            v-model="searchQuery"
            placeholder="Pesquisar"
            outlined
            hide-details
            dense
            class="user-search text-capitalize col-sm-6 col-10 pa-2"
          ></v-text-field>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                color="success"
                :content="filteredOrders.length"
                :value="filterApply && filteredOrders.length > 0"
                overlap
              >
                <v-btn icon @click="applyFilter()" v-bind="attrs" v-on="on">
                  <v-icon :color="filterApply ? 'success' : 'secondary'">{{
                    icons.mdiFilter
                  }}</v-icon>
                </v-btn>
              </v-badge>
            </template>
            <span>{{ filterApply ? "Retirar Filtro" : "Aplicar Filtro" }}</span>
          </v-tooltip>
        </div>
        <div class="my-2" :class="{ 'w-full py-2': isMobile }">
          <v-btn
            color="accent"
            :disabled="selectedOrders.length == 0"
            class="ma-sm-2 my-2 text-capitalize"
            @click="bill()"
            :block="isMobile"
          >
            <v-icon size="17" class="me-1">{{ icons.mdiCheck }}</v-icon>
            <span>Faturar</span>
          </v-btn>

          <v-btn
            color="secondary"
            class="ma-sm-2 my-2 text-capitalize"
            @click="openImportOrders()"
            :block="isMobile"
          >
            <v-icon size="17" class="me-1">{{ icons.mdiFileExcel }}</v-icon>
            <span>Importar</span>
          </v-btn>

          <vue-excel-xlsx
            :data="export_orders"
            :columns="columns"
            :file-name="'order'"
            :file-type="'xlsx'"
            :sheet-name="'sheetname'"
          >
            <v-btn
              color="success"
              class="ma-sm-2 my-2 text-capitalize"
              :block="isMobile"
            >
              <v-icon size="17" class="me-1">{{ icons.mdiFileExcel }}</v-icon>
            <span>Exportar</span>
            </v-btn>
          </vue-excel-xlsx>

          <v-btn
            color="primary"
            class="ma-sm-2 my-2 text-capitalize"
            @click="openSyncOrdersPlatforms()"
            :block="isMobile"
          >
            <v-icon size="17" class="me-1">{{ icons.mdiSyncAlert }}</v-icon>
            <span>Sincronizar</span>
          </v-btn>
        </div>
      </v-card-title>

      <v-data-table
        :items-per-page="25"
        :page.sync="page"
        show-select
        item-key="id"
        v-model="selectedOrders"
        :headers="tableColumns"
        :items="orders"
        :loading="loading"
        loading-text="Carregando Tabela"
        :footer-props="{
          itemsPerPageOptions: [25, 50, 100, -1],
          itemsPerPageText: 'Itens por pagina',
          itemsPerPageAllText: 'Todos',
          disablePagination: false,
          nextIcon: '',
          prevIcon: '',
          pageText: '',
        }"
      >
        <template v-slot:header.data-table-select>
          <v-simple-checkbox
            :ripple="false"
            :value="selected"
            @input="selectAll"
          ></v-simple-checkbox>
        </template>

        <template v-slot:item.data-table-select="{ item, isSelected, select }">
          <v-simple-checkbox
            :value="isSelected"
            :ripple="false"
            :readonly="
              item.billed == 1 ||
              item.status === 'cancelled' ||
              item.status === 'draft' ||
              item.status === 'pendding'
            "
            :disabled="
              item.billed == 1 ||
              item.status === 'cancelled' ||
              item.status === 'draft' ||
              item.status === 'pendding'
            "
            :style="{ opacity: item.billed ? 0.2 : 1 }"
            @input="select($event)"
          ></v-simple-checkbox>
        </template>

        <template v-slot:item.order_customer="{ item }">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <span>{{ item.order_customer.name }}</span>
            </div>
          </div>
        </template>

        <template v-slot:item.ordered_at="{ item }">
          {{ item.ordered_at | dateBR }}
        </template>

        <template v-slot:item.billed_in="{ item }">
          {{ item.billed_in | dateBR }}
        </template>

        <template v-slot:item.value="{ item }">
          {{ (item.value / 100) | moneyBr }}
        </template>

        <template v-slot:item.billed="{ item }">
          <v-chip
            small
            :color="resolveOrderBilledVariant(item.billed)"
            :class="`${resolveOrderBilledVariant(item.billed)}--text`"
            class="font-weight-semibold text-capitalize"
          >
            <div v-if="item.billed === 1">Faturado</div>
            <div v-if="item.billed === 0">Não Faturado</div>
          </v-chip>
        </template>

        <template v-slot:item.status="{ item }">
          <v-chip
            small
            class="font-weight-semibold text-capitalize"
            :color="resolveStatusVariant(item.status)"
          >
            <div v-if="item.status === 'draft'">Rascunho</div>
            <div v-if="item.status === 'pendding'">Pendente</div>
            <div v-if="item.status === 'order'">Processado</div>
            <div v-if="item.status === 'cancelled'">Cancelado</div>
          </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn icon @click="detailOrder(item)">
            <v-icon>{{ icons.mdiInformation }}</v-icon>
          </v-btn>
        </template>

        <template v-slot:footer class="d-flex">
          <v-pagination 
            v-model="page" 
            :length="pageCount" 
            :total-visible="7"
            @input="getOrders"
            >
          </v-pagination>
        </template>
      </v-data-table>
    </v-card>
    <order-form ref="OrderForm" @onSubmit="getOrders"></order-form>
    <orders-filter-component
      ref="filterForm"
      @filter="filters($event)"
    ></orders-filter-component>
    <sync-orders-platforms ref="syncOrder" />
    <order-bill ref="orderbill" />
  </div>
</template>

<script>
import Filter from "@/util/Filters";
import OrderForm from "@/components/orders/form/OrderDetail.vue";
import OrdersFilterComponent from "@/components/app/filters/orders/ordersFilterComponent.vue";
import SyncOrdersPlatforms from "@/components/orders/form/SyncOrdersPlatforms.vue";
import NothingComponent from "@/components/nothingComponent.vue";
import OrderBill from "@/components/orders/form/OrderBill.vue";
import {
  mdiExportVariant,
  mdiInformation,
  mdiPlus,
  mdiCurrencyUsd,
  mdiDotsHorizontal,
  mdiFileExcel,
  mdiFilter,
  mdiCheck,
  mdiSyncAlert,
  mdiShopping,
} from "@mdi/js";
import orderMixin from "@/util/mixins/orderMixin";
import tableMixin from "@/util/mixins/tableMixin";

const formatador = Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
  minimumFractionDigits: 2,
});

export default {
  mixins: [orderMixin, tableMixin],
  components: {
    OrderForm,
    OrdersFilterComponent,
    SyncOrdersPlatforms,
    SyncOrdersPlatforms,
    NothingComponent,
    OrderBill,
  },

  data() {
    return {
      searchQuery: null,
      loading: false,

      icons: {
        mdiFilter,
        mdiPlus,
        mdiExportVariant,
        mdiInformation,
        mdiDotsHorizontal,
        mdiCurrencyUsd,
        mdiFileExcel,
        mdiCheck,
        mdiSyncAlert,
        mdiShopping,
      },

      filterApply: false,
      filteredOrders: [],

      ordersTotal: {
        total: 1000.0,
        title: "Vendas",
      },

      export_orders: [],

      columns: [
        {
          label: "Numero",
          field: "number",
        },
        {
          label: "Cliente",
          field: "order_customer.name",
        },
        {
          label: "Faturado",
          field: "billed",
        },
        {
          label: "Faturado em",
          field: "billed_at",
        },
        {
          label: "PLataforma",
          field: "platform",
        },
        {
          label: "Transacao",
          field: "platform_transaction",
        },
        {
          label: "Valor",
          field: "value",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Criado em",
          field: "created_at",
        },
      ],

      tableColumns: [
        { text: "Cliente", value: "order_customer", sortable: false },
        { text: "Plataforma", value: "platform", sortable: false },
        { text: "Transacao", value: "platform_transaction", sortable: false },
        { text: "Data", value: "ordered_at", sortable: false },
        { text: "Faturar", value: "billed_in", sortable: false },
        { text: "Valor", value: "value", sortable: false },
        { text: "Faturado", value: "billed", sortable: false },
        { text: "Situação", value: "status", sortable: false },
        { text: "AÇÕES", value: "actions", sortable: false },
      ],

      dashboard: {
        ordersTotal: 0.0,
        cancelleds: 0.0,
        billeds: 0.0,
      },
      orders: [],
      selectedOrders: [],

      selected: false,
      page: null,
      pageCount: null,

      searchQuery: null,
    };
  },

  computed: {
    orderShow() {
      return this.filterApply ? this.filteredOrders : this.orders;
    },

    toBillSum() {
      let billedSum = 0;

      this.selectedOrders.forEach((item) => {
        billedSum += item.value;
      });

      return billedSum;
    },
  },

  mounted() {
    this.getOrders();
    this.getOrdersDashboards();
  },

  methods: {
    getOrders() {
      this.loading = true;
      this.$http
        .index(`/order?page=${this.page}`, this.searchQuery)
        .then((res) => {
          this.orders = res.data.data;
          this.getOrdersDashboards();
          this.exportOrders();
          this.pageCount = res.data.last_page;
          this.page = res.data.current_page;
          this.loading = false;
        })
        .catch((err) => console.log(err));
    },

    getOrdersDashboards() {
      this.loading = true;
      this.$http
        .$get("/order-dashboard", this.searchQuery)
        .then((response) => {
          this.dashboard.ordersTotal = response.ordersTotal.total;
          this.dashboard.cancelleds = response.cancelleds.total;
          this.dashboard.billeds = response.billeds.total;
          this.loading = false;
        })
        .catch((err) => console.log(err));
    },

    avatarText(value) {
      if (!value) return "";
      const nameArray = value.split(" ");

      return nameArray.map((word) => word.charAt(0).toUpperCase()).join("");
    },

    openImportOrders() {
      this.$router.push("vendas/importar");
    },

    openSyncOrdersPlatforms() {
      this.$refs.syncOrder.open();
    },

    bill() {
      const ids = this.getIdsOfSelectedsOrders();

      this.$refs.orderbill.open(ids);
    },

    parseCurrency(num) {
      return formatador.format(num / 100);
    },

    detailOrder(item) {
      this.$router.push(`/vendas/detalhes/${item.id}`);
    },

    applyFilter() {
      if (this.filterApply) {
        this.filterApply = false;
      } else {
        this.$refs.filterForm.open();
      }
    },

    selectAll() {
      this.selectedOrders = this.selected
        ? []
        : this.orderShow.filter(
            (element) =>
              !element.billed &&
              element.status != "cancelled" &&
              element.status != "draft" &&
              element.status != "pendding"
          );
      this.selected = this.selected ? false : true;
    },

    getIdsOfSelectedsOrders() {
      var id = [];

      this.selectedOrders.forEach((item) => {
        id.push(item.id);
      });

      return id;
    },

    filters(event) {
      this.searchQuery = event;
      this.getOrders();
    },

    exportOrders() {
      this.$http
        .index("/order-export", this.searchQuery)
        .then((response) => {
          this.export_orders = response.data
        }).catch(error => {
          console.log(error)
        });
    },
  },
};
</script>
